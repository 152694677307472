import {
    ColGroupDef,
    GridApi,
    ColDef,
    ICellRendererParams,
    ProcessCellForExportParams,
    RowNode,
    GridReadyEvent,
    RowDoubleClickedEvent,
} from 'ag-grid-community';
import { format, getYear } from 'date-fns';

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { switchMap, of } from 'rxjs';

import { UtilsService } from '@app/core/services/base/utils.service';
import { MacroprojectsService } from '@app/core/services/macroprojects.service';
import { ProjectsService } from '@app/core/services/projects.service';
import { SnapshotsService } from '@app/core/services/snapshots.service';

import { Constants } from '@app/core/constants/constants';
import { agNumberFormatter } from '@app/shared/ag-grid-utils/value-formatters/ag-number-formatter';
import { Macroproject, MacroprojectPlanning, MacroprojectWithPlannings } from '@app/shared/models/macroproject';
import { Project, ProjectTask, ProjectWithTasks } from '@app/shared/models/project';
import { SortData } from '@app/shared/models/sort-data';

import { ClusterFormDialogComponent } from '../cluster-form-dialog/cluster-form-dialog.component';

@Component({
    selector: 'ppm-cluster-grid',
    templateUrl: './cluster-grid.component.html',
    styleUrls: ['./cluster-grid.component.scss'],
})
export class ClusterGridComponent implements OnInit, OnChanges {
    public macroprojectsWithPlannings: MacroprojectWithPlannings[] = [];
    public projectsWithTasks: ProjectWithTasks[] = [];
    public overviewColumnDefs: ColGroupDef[] = [];
    public overviewRowData: Record<string, any>[] = [];
    public overviewRowTotal: Record<string, any>[] = [];
    public overviewGridApi!: GridApi;
    public defaultColDef!: ColDef;
    public pageSize = 20;

    @Input() roadmap!: number | null;
    @Input() yearFrom!: Date;
    @Input() snapshot!: number;
    @Input() editable = false;

    constructor(
        private dialog: MatDialog,
        private macroprojectsService: MacroprojectsService,
        private projectsService: ProjectsService,
        private snapshotsService: SnapshotsService,
        private utilsService: UtilsService
    ) {}

    get projectTimePeriod(): number[] {
        const yearStart = getYear(this.yearFrom);

        return new Array(5).fill(yearStart).map((year: number, index: number) => year + index);
    }

    ngOnChanges(): void {
        this.getRoadmapData();
    }

    ngOnInit(): void {
        this.defaultColDef = { filter: true };
        this.listenForSelectedMacroprojectUpdates();
    }

    listenForSelectedMacroprojectUpdates(): void {
        this.macroprojectsService.macroprojectUpdated$.subscribe((macroproject: Macroproject) => {
            this.overviewGridApi.forEachNode((rowNode: RowNode) => {
                if (rowNode.data.id === macroproject.id) {
                    rowNode.data.roadmap = macroproject.roadmap.name;
                    rowNode.data.stream = macroproject.stream;
                    rowNode.data.macroproject = macroproject.name;
                    rowNode.data.wbe = macroproject.wbe;
                    rowNode.data.cluster = macroproject?.cluster?.name;
                    rowNode.data.subcategory = macroproject?.cluster_category?.name;
                    this.overviewGridApi.applyTransaction({ update: [rowNode.data] });
                }
            });
        });
    }

    getMacroprojectListFilters(): Record<string, any> {
        let filters: Record<string, any> = {};

        if (this.snapshot === -1) {
            filters = this.roadmap !== null ? { roadmap: this.roadmap } : {};
        } else {
            filters =
                this.roadmap !== null ? { _and: [{ roadmap: this.roadmap }, { snapshot: this.snapshot }] } : { snapshot: this.snapshot };
        }

        return filters;
    }

    generateProjectPeriodObject(prefix: string): any {
        return this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
            accumulator[`${prefix}_${year}`] = 0;
            return accumulator;
        }, {});
    }

    getRoadmapData(): void {
        let projects!: Project[];
        let macroprojects!: Macroproject[];
        const sortBy: SortData = { by: 'name', direction: 'ASC' };
        const macroprojectListFilters = this.getMacroprojectListFilters();
        const macroprojectListRequest =
            this.snapshot === -1
                ? this.macroprojectsService.getMacroprojectsFull(macroprojectListFilters, sortBy)
                : this.snapshotsService.getMacroprojectsSnapshotsFull(macroprojectListFilters, sortBy);

        macroprojectListRequest
            .pipe(
                switchMap((response: Macroproject[]) => {
                    macroprojects = response;
                    const planningListFilters = this.utilsService.removeUndefinedAndNullFromObject({
                        macro_project:
                            this.roadmap !== null ? { _in: response.map((macroproject: Macroproject) => macroproject.id) } : null,
                        execution_year: {
                            _between: [this.projectTimePeriod[0], this.projectTimePeriod.pop()],
                        },
                    });
                    const planningListRequest =
                        this.snapshot === -1
                            ? this.macroprojectsService.getMacroprojectPlannings(planningListFilters)
                            : this.snapshotsService.getMacroprojectPlanningsSnapshots(this.snapshot, planningListFilters);
                    return response.length ? planningListRequest : of([]);
                }),
                switchMap((response: MacroprojectPlanning[]) => {
                    this.macroprojectsWithPlannings = macroprojects.map((macroproject: Macroproject) => ({
                        ...macroproject,
                        plannings: response.filter((planning: MacroprojectPlanning) => planning.macro_project === macroproject.id),
                    }));
                    const projectListFilters = this.utilsService.removeUndefinedAndNullFromObject({
                        macro_project:
                            this.roadmap !== null ? { _in: macroprojects.map((macroproject: Macroproject) => macroproject.id) } : null,
                    });
                    const projectListRequest =
                        this.snapshot === -1
                            ? this.projectsService.getProjects(projectListFilters)
                            : this.snapshotsService.getProjectsSnapshots(this.snapshot, projectListFilters);
                    return response.length ? projectListRequest : of([]);
                }),
                switchMap((response: Project[]) => {
                    projects = response;
                    const taskListFilters = this.utilsService.removeUndefinedAndNullFromObject({
                        project: this.roadmap !== null ? { _in: response.map((project: Project) => project.id) } : null,
                        execution_year: {
                            _between: [this.projectTimePeriod[0], this.projectTimePeriod.pop()],
                        },
                    });
                    const taskListRequest =
                        this.snapshot === -1
                            ? this.projectsService.getProjectTasks(taskListFilters)
                            : this.snapshotsService.getProjectTasksSnapshots(this.snapshot, taskListFilters);
                    return response.length ? taskListRequest : of([]);
                })
            )
            .subscribe((response: ProjectTask[]) => {
                this.projectsWithTasks = projects.map((project: Project) => ({
                    ...project,
                    tasks: response.filter((task: ProjectTask) => task.project === project.id),
                }));

                this.setupOverviewTable();
            });
    }

    setupOverviewTable(): void {
        const totalsRowData = {
            capex: { ...this.generateProjectPeriodObject('capex'), total: 0 },
            amortization: { ...this.generateProjectPeriodObject('amortization'), total: 0 },
            additionalFte: { ...this.generateProjectPeriodObject('fte'), total: 0 },
            additionalItFteCost: { ...this.generateProjectPeriodObject('it_fte_cost'), total: 0 },
            additionalBssFteCost: { ...this.generateProjectPeriodObject('bss_fte_cost'), total: 0 },
            additionalItRunningCost: { ...this.generateProjectPeriodObject('it_running_cost'), total: 0 },
            additionalBssRunningCost: { ...this.generateProjectPeriodObject('bss_running_cost'), total: 0 },
            runningCostRecap: { ...this.generateProjectPeriodObject('running_cost_recap'), total: 0 },
            projectImpact: { total: 0 },
            margin: { ...this.generateProjectPeriodObject('margins'), total: 0 },
            savings: { ...this.generateProjectPeriodObject('running_savings'), total: 0 },
            benefit: { ...this.generateProjectPeriodObject('benefit_recap'), total: 0 },
            bssCaseNoFte: { ...this.generateProjectPeriodObject('bss_no_fte'), total: 0 },
            freeUpTimeFte: { ...this.generateProjectPeriodObject('free_fte'), total: 0 },
            freeUpTimeEur: { total: 0 },
            noGoImpact: { ...this.generateProjectPeriodObject('no_go_impact'), total: 0 },
            bssCaseWithFte: { total: 0 },
            totalBenefitRecap: { total: 0 },
        };

        this.overviewColumnDefs = [
            {
                headerName: '',
                children: [
                    { field: 'id', hide: true },
                    {
                        headerName: 'Roadmap',
                        field: 'roadmap',
                        sortable: true,
                        width: 130,
                        sort: 'asc',
                        sortIndex: 0,
                        pinned: 'left',
                        resizable: true,
                    },
                ],
            },
            {
                headerName: 'Macroproject',
                children: [
                    {
                        headerName: `Name`,
                        field: `macroproject`,
                        width: 200,
                        resizable: true,
                        pinned: 'left',
                    },
                    {
                        headerName: `WBE`,
                        field: `wbe`,
                        pinned: 'left',
                        columnGroupShow: 'open',
                        hide: true,
                    },
                    {
                        headerName: `Approval status`,
                        field: `approved`,
                        pinned: 'left',
                        columnGroupShow: 'open',
                        suppressMenu: true,
                        cellRendererSelector: (selectorParams: ICellRendererParams) => {
                            // use cell renderer only on non-pinned rows
                            if (!selectorParams.node.rowPinned) {
                                return {
                                    component: (params: ICellRendererParams) => {
                                        if (params.value === null) {
                                            return '';
                                        }

                                        return params.value === true ? 'Approved' : 'Not Approved';
                                    },
                                };
                            } else {
                                return undefined;
                            }
                        },
                    },
                ],
            },
            {
                headerName: 'Cluster',
                children: [
                    {
                        headerName: `Cluster`,
                        field: `cluster`,
                        width: 180,
                        sort: 'asc',
                        sortIndex: 1,
                        resizable: true,
                    },
                    {
                        headerName: `Project category`,
                        field: `typology`,
                        columnGroupShow: 'open',
                    },
                    {
                        headerName: `Subcategory`,
                        field: `subcategory`,
                        columnGroupShow: 'open',
                        hide: true,
                    },
                    {
                        headerName: `Project type`,
                        field: `project_type`,
                        columnGroupShow: 'open',
                    },
                    {
                        headerName: `Board member`,
                        field: `board_member`,
                        columnGroupShow: 'open',
                    },
                ],
            },
            {
                headerName: 'CAPEX',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `capex_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 90,
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'capex_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        sort: 'desc',
                        sortIndex: 2,
                        width: 120,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Amortization',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `amortization_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'amortization_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Add. FTEs',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `fte_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Last Year',
                        field: 'fte_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Add. IT FTE Cost',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `it_fte_cost_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'it_fte_cost_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Add. BSS FTE Cost',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `bss_fte_cost_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'bss_fte_cost_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Add. IT Run. Cost',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `it_running_cost_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'it_running_cost_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Add. BSS Run. Cost',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `bss_running_cost_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'bss_running_cost_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Run. Cost Recap',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `running_cost_recap_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'running_cost_recap_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Prj Impact',
                children: [
                    {
                        headerName: 'Total',
                        field: 'project_impact',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Add. margins',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `margins_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'margins_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Run. Cost savings',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `running_savings_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'running_savings_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Benefit recap',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `benefit_recap_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'benefit_recap_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'BC without free FTE & NoGo Impact',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `bss_no_fte_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'bss_no_fte_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'ROI 1',
                children: [
                    {
                        headerName: 'Total',
                        field: 'flat_ROI',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'ROI 1%',
                children: [
                    {
                        headerName: 'Total',
                        field: 'ROI',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                        cellRenderer: (params: ICellRendererParams) => {
                            return params.valueFormatted ? `${params.valueFormatted}%` : params.valueFormatted;
                        },
                    },
                ],
            },
            {
                headerName: 'Free-up time in FTEs',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `free_fte_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Last year',
                        field: 'free_fte_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Free-up time in EUR',
                children: [
                    {
                        headerName: 'Total',
                        field: 'free_eur_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'NoGo impact',
                children: [
                    ...this.projectTimePeriod.map((year: number) => ({
                        headerName: `${year}`,
                        field: `no_go_impact_${year}`,
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        valueFormatter: agNumberFormatter,
                    })),
                    {
                        headerName: 'Total',
                        field: 'no_go_impact_yearly_total',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'Total Benefit Recap',
                children: [
                    {
                        headerName: 'Total',
                        field: 'total_benefit_recap',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'BC with free FTE & NoGo Impact',
                children: [
                    {
                        headerName: 'Total',
                        field: 'bss_case_fte',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'ROI 2',
                children: [
                    {
                        headerName: 'Total',
                        field: 'flat_ROI2',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                    },
                ],
            },
            {
                headerName: 'ROI 2%',
                children: [
                    {
                        headerName: 'Total',
                        field: 'ROI2',
                        type: 'rightAligned',
                        filter: 'agNumberColumnFilter',
                        width: 150,
                        cellClass: ['ppm-grid-cell-highlight', 'ppm-bold', 'ag-right-aligned-cell'],
                        valueFormatter: agNumberFormatter,
                        cellRenderer: (params: ICellRendererParams) => {
                            return params.valueFormatted ? `${params.valueFormatted}%` : params.valueFormatted;
                        },
                    },
                ],
            },
        ];

        this.overviewRowData = this.macroprojectsWithPlannings.map((macroproject: MacroprojectWithPlannings) => {
            let capexTotal = 0;
            const yearlyCapex = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`capex_${year}`] = this.projectsWithTasks
                    .filter((project: ProjectWithTasks) => project.macro_project.id === macroproject.id)
                    .map((project: ProjectWithTasks) =>
                        project.tasks
                            .filter((task: ProjectTask) => task.execution_year === year)
                            .reduce((acc: number, task: ProjectTask) => acc + task.capex, 0)
                    )
                    .reduce((sum: number, capex: number) => sum + capex, 0);
                capexTotal += accumulator[`capex_${year}`];
                totalsRowData.capex[`capex_${year}`] += accumulator[`capex_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.capex.total += capexTotal;

            let amortizationTotal = 0;
            const yearlyAmortization = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number, index: number) => {
                accumulator[`amortization_${year}`] = yearlyCapex[`capex_${year}`] / 5 + (accumulator[`amortization_${year - 1}`] ?? 0);
                amortizationTotal += accumulator[`amortization_${year}`];

                // depreciation can not be greater than capex
                if (amortizationTotal > capexTotal) {
                    accumulator[`amortization_${year}`] -= amortizationTotal - capexTotal;
                    amortizationTotal = capexTotal;
                }

                totalsRowData.amortization[`amortization_${year}`] += accumulator[`amortization_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.amortization.total += amortizationTotal;

            let fteTotal = 0;
            const yearlyFte = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`fte_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce(
                        (fteSum: number, planning: MacroprojectPlanning) =>
                            fteSum + planning.additional_it_fte + planning.additional_bss_fte,
                        0
                    );
                fteTotal = accumulator[`fte_${year}`];
                totalsRowData.additionalFte[`fte_${year}`] += accumulator[`fte_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.additionalFte.total =
                totalsRowData.additionalFte[`fte_${this.projectTimePeriod[this.projectTimePeriod.length - 1]}`];

            let itFteCostTotal = 0;
            const yearlyItFteCost = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`it_fte_cost_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce(
                        (fteSum: number, planning: MacroprojectPlanning) =>
                            fteSum + planning.additional_it_fte * Constants.ADDITIONAL_FTE_COST,
                        0
                    );
                itFteCostTotal += accumulator[`it_fte_cost_${year}`];
                totalsRowData.additionalItFteCost[`it_fte_cost_${year}`] += accumulator[`it_fte_cost_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.additionalItFteCost.total += itFteCostTotal;

            let bssFteCostTotal = 0;
            const yearlyBssFteCost = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`bss_fte_cost_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce(
                        (fteSum: number, planning: MacroprojectPlanning) =>
                            fteSum + planning.additional_bss_fte * Constants.ADDITIONAL_FTE_COST,
                        0
                    );
                bssFteCostTotal += accumulator[`bss_fte_cost_${year}`];
                totalsRowData.additionalBssFteCost[`bss_fte_cost_${year}`] += accumulator[`bss_fte_cost_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.additionalBssFteCost.total += bssFteCostTotal;

            let itRunningCostTotal = 0;
            const yearlyItRunningCost = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`it_running_cost_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce((fteSum: number, planning: MacroprojectPlanning) => fteSum + planning.it_running_cost, 0);
                itRunningCostTotal += accumulator[`it_running_cost_${year}`];
                totalsRowData.additionalItRunningCost[`it_running_cost_${year}`] += accumulator[`it_running_cost_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.additionalItRunningCost.total += itRunningCostTotal;

            let bssRunningCostTotal = 0;
            const yearlyBssRunningCost = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`bss_running_cost_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce((fteSum: number, planning: MacroprojectPlanning) => fteSum + planning.bss_running_cost, 0);
                bssRunningCostTotal += accumulator[`bss_running_cost_${year}`];
                totalsRowData.additionalBssRunningCost[`bss_running_cost_${year}`] += accumulator[`bss_running_cost_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.additionalBssRunningCost.total += bssRunningCostTotal;

            let runningCostRecapTotal = 0;
            const yearlyRunningCostRecap = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`running_cost_recap_${year}`] =
                    yearlyFte[`fte_${year}`] * Constants.ADDITIONAL_FTE_COST +
                    yearlyItRunningCost[`it_running_cost_${year}`] +
                    yearlyBssRunningCost[`bss_running_cost_${year}`];
                runningCostRecapTotal += accumulator[`running_cost_recap_${year}`];
                totalsRowData.runningCostRecap[`running_cost_recap_${year}`] += accumulator[`running_cost_recap_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.runningCostRecap.total += runningCostRecapTotal;

            let marginsTotal = 0;
            const yearlyMargin = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`margins_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce((marginSum: number, planning: MacroprojectPlanning) => marginSum + planning.additional_margin, 0);
                marginsTotal += accumulator[`margins_${year}`];
                totalsRowData.margin[`margins_${year}`] += accumulator[`margins_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.margin.total += marginsTotal;

            let savingsTotal = 0;
            const yearlySavings = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`running_savings_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce((savingSum: number, planning: MacroprojectPlanning) => savingSum + planning.additional_savings, 0);
                savingsTotal += accumulator[`running_savings_${year}`];
                totalsRowData.savings[`running_savings_${year}`] += accumulator[`running_savings_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.savings.total += savingsTotal;

            let benefitTotal = 0;
            const yearlyBenefits = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`benefit_recap_${year}`] = yearlyMargin[`margins_${year}`] + yearlySavings[`running_savings_${year}`];
                benefitTotal += accumulator[`benefit_recap_${year}`];
                totalsRowData.benefit[`benefit_recap_${year}`] += accumulator[`benefit_recap_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.benefit.total += benefitTotal;

            let bssNoFteTotal = 0;
            const yearlyBssNoFte = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`bss_no_fte_${year}`] =
                    yearlyBenefits[`benefit_recap_${year}`] -
                    yearlyRunningCostRecap[`running_cost_recap_${year}`] -
                    yearlyAmortization[`amortization_${year}`];
                bssNoFteTotal += accumulator[`bss_no_fte_${year}`];
                totalsRowData.bssCaseNoFte[`bss_no_fte_${year}`] += accumulator[`bss_no_fte_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.bssCaseNoFte.total += bssNoFteTotal;

            let freeFteTotal = 0;
            const yearlyFreeFte = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`free_fte_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce((freeFteSum: number, planning: MacroprojectPlanning) => freeFteSum + planning.free_up_time_fte, 0);
                freeFteTotal += accumulator[`free_fte_${year}`];
                totalsRowData.freeUpTimeFte[`free_fte_${year}`] += accumulator[`free_fte_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.freeUpTimeFte.total =
                totalsRowData.freeUpTimeFte[`free_fte_${this.projectTimePeriod[this.projectTimePeriod.length - 1]}`];

            let noGoImpactTotal = 0;
            const yearlyNoGoImpact = this.projectTimePeriod.reduce((accumulator: Record<string, number>, year: number) => {
                accumulator[`no_go_impact_${year}`] = macroproject.plannings
                    .filter((planning: MacroprojectPlanning) => planning.execution_year === year)
                    .reduce((noGoImpactSum: number, planning: MacroprojectPlanning) => noGoImpactSum + planning.no_go_impact, 0);
                noGoImpactTotal += accumulator[`no_go_impact_${year}`];
                totalsRowData.noGoImpact[`no_go_impact_${year}`] += accumulator[`no_go_impact_${year}`];
                return accumulator;
            }, {} as Record<string, number>);
            totalsRowData.noGoImpact.total += noGoImpactTotal;

            totalsRowData.projectImpact.total += runningCostRecapTotal + amortizationTotal;
            totalsRowData.freeUpTimeEur.total += freeFteTotal * Constants.ADDITIONAL_FTE_COST;
            totalsRowData.bssCaseWithFte.total += freeFteTotal * Constants.ADDITIONAL_FTE_COST + bssNoFteTotal + noGoImpactTotal;
            totalsRowData.totalBenefitRecap.total += freeFteTotal * Constants.ADDITIONAL_FTE_COST + benefitTotal;

            return {
                id: macroproject.id,
                roadmap: macroproject.roadmap.name,
                stream: macroproject.stream,
                macroproject: macroproject.name,
                wbe: macroproject.wbe ?? '',
                typology: macroproject?.typology?.name ?? '',
                project_type: macroproject?.project_type?.name ?? '',
                board_member: macroproject?.board_member,
                approved: macroproject.approved,
                cluster: macroproject?.cluster?.name,
                subcategory: macroproject?.cluster_category?.name,
                ...yearlyCapex,
                capex_yearly_total: capexTotal,
                ...yearlyAmortization,
                amortization_yearly_total: amortizationTotal,
                ...yearlyFte,
                fte_yearly_total: fteTotal,
                itFteCostTotal,
                ...yearlyItFteCost,
                it_fte_cost_yearly_total: itFteCostTotal,
                ...yearlyBssFteCost,
                bss_fte_cost_yearly_total: bssFteCostTotal,
                ...yearlyItRunningCost,
                it_running_cost_yearly_total: itRunningCostTotal,
                ...yearlyBssRunningCost,
                bss_running_cost_yearly_total: bssRunningCostTotal,
                ...yearlyRunningCostRecap,
                running_cost_recap_yearly_total: runningCostRecapTotal,
                project_impact: runningCostRecapTotal + amortizationTotal,
                ...yearlyMargin,
                margins_yearly_total: marginsTotal,
                ...yearlySavings,
                running_savings_yearly_total: savingsTotal,
                ...yearlyBenefits,
                benefit_recap_yearly_total: benefitTotal,
                ...yearlyBssNoFte,
                bss_no_fte_yearly_total: bssNoFteTotal,
                ...yearlyFreeFte,
                free_fte_yearly_total: yearlyFreeFte[`free_fte_${this.projectTimePeriod.pop() as number}`],
                free_eur_yearly_total: freeFteTotal * Constants.ADDITIONAL_FTE_COST,
                ...yearlyNoGoImpact,
                no_go_impact_yearly_total: noGoImpactTotal,
                bss_case_fte: freeFteTotal * Constants.ADDITIONAL_FTE_COST + bssNoFteTotal + noGoImpactTotal,
                total_benefit_recap: freeFteTotal * Constants.ADDITIONAL_FTE_COST + benefitTotal,
                flat_ROI: capexTotal ? bssNoFteTotal / capexTotal : 0,
                ROI: capexTotal ? (bssNoFteTotal / capexTotal) * 100 : 0,
                flat_ROI2: capexTotal ? (freeFteTotal * Constants.ADDITIONAL_FTE_COST + bssNoFteTotal + noGoImpactTotal) / capexTotal : 0,
                ROI2: capexTotal
                    ? ((freeFteTotal * Constants.ADDITIONAL_FTE_COST + bssNoFteTotal + noGoImpactTotal) / capexTotal) * 100
                    : 0,
            };
        });

        this.overviewRowTotal = [
            {
                ...totalsRowData.capex,
                capex_yearly_total: totalsRowData.capex.total,
                ...totalsRowData.amortization,
                amortization_yearly_total: totalsRowData.amortization.total,
                ...totalsRowData.additionalFte,
                fte_yearly_total: totalsRowData.additionalFte.total,
                ...totalsRowData.additionalItFteCost,
                it_fte_cost_yearly_total: totalsRowData.additionalItFteCost.total,
                ...totalsRowData.additionalBssFteCost,
                bss_fte_cost_yearly_total: totalsRowData.additionalBssFteCost.total,
                ...totalsRowData.additionalItRunningCost,
                it_running_cost_yearly_total: totalsRowData.additionalItRunningCost.total,
                ...totalsRowData.additionalBssRunningCost,
                bss_running_cost_yearly_total: totalsRowData.additionalBssRunningCost.total,
                ...totalsRowData.runningCostRecap,
                running_cost_recap_yearly_total: totalsRowData.runningCostRecap.total,
                project_impact: totalsRowData.projectImpact.total,
                ...totalsRowData.margin,
                margins_yearly_total: totalsRowData.margin.total,
                ...totalsRowData.savings,
                running_savings_yearly_total: totalsRowData.savings.total,
                ...totalsRowData.benefit,
                benefit_recap_yearly_total: totalsRowData.benefit.total,
                ...totalsRowData.bssCaseNoFte,
                bss_no_fte_yearly_total: totalsRowData.bssCaseNoFte.total,
                ...totalsRowData.freeUpTimeFte,
                free_fte_yearly_total: totalsRowData.freeUpTimeFte.total,
                free_eur_yearly_total: totalsRowData.freeUpTimeEur.total,
                ...totalsRowData.noGoImpact,
                no_go_impact_yearly_total: totalsRowData.noGoImpact.total,
                bss_case_fte: totalsRowData.bssCaseWithFte.total,
                total_benefit_recap: totalsRowData.totalBenefitRecap.total,
            },
        ];
    }

    /**
     * This method is called whenever the grid filtering or pagination changes. It will be triggered
     * after the setupOverviewTable() method and the totals will be updated with the current page
     * rows.
     */
    setTotalsRow(): void {
        if (this.overviewGridApi) {
            const currentPage = this.overviewGridApi.paginationGetCurrentPage();

            // reset totals
            Object.keys(this.overviewRowTotal[0]).forEach((key: string) => (this.overviewRowTotal[0][key] = 0));

            // set totals with filtered rows
            this.overviewGridApi.forEachNodeAfterFilterAndSort((node: RowNode) => {
                // get only current page nodes
                Object.keys(node.data)
                    .filter(
                        (key: string) =>
                            ![
                                'id',
                                'roadmap',
                                'stream',
                                'macroproject',
                                'wbe',
                                'cluster',
                                'subcategory',
                                'approved',
                                'flat_ROI',
                                'ROI',
                                'flat_ROI2',
                                'ROI2',
                            ].includes(key)
                    )
                    .forEach((key: string) => {
                        this.overviewRowTotal[0][key] += node.data[key];
                    });
            });

            this.overviewRowTotal = [...this.overviewRowTotal];
        }
    }

    onGridReady(event: GridReadyEvent): void {
        this.overviewGridApi = event.api;
    }

    onEditRowDetails(event: RowDoubleClickedEvent): void {
        if (this.editable && this.snapshot === -1) {
            const selectedMacroprojectIndex = this.macroprojectsWithPlannings.findIndex(
                (macroproject: MacroprojectWithPlannings) => macroproject.id === event.data.id
            );
            const dialogRef = this.dialog.open(ClusterFormDialogComponent, {
                width: '600px',
                autoFocus: false,
                data: {
                    row: event.data,
                    macroproject: this.macroprojectsWithPlannings[selectedMacroprojectIndex],
                },
            });

            dialogRef.afterClosed().subscribe((result: Macroproject | null) => {
                if (result) {
                    this.macroprojectsWithPlannings[selectedMacroprojectIndex] = {
                        ...this.macroprojectsWithPlannings[selectedMacroprojectIndex],
                        ...result,
                    };
                    this.overviewGridApi.forEachNode((rowNode: RowNode) => {
                        if (rowNode.data.id === result?.id) {
                            rowNode.data.roadmap = result?.roadmap.name;
                            rowNode.data.typology = result?.typology?.name;
                            rowNode.data.project_type = result?.project_type?.name;
                            rowNode.data.board_member = result?.board_member;
                            rowNode.data.cluster = result?.cluster?.name;
                            rowNode.data.subcategory = result?.cluster_category?.name;
                            rowNode.data.approved = result?.approved;
                            this.overviewGridApi.applyTransaction({ update: [rowNode.data] });
                        }
                    });
                }
            });
        }
    }

    onExportAsCsv(): void {
        const fileName = this.roadmap ? 'roadmap_overview' : 'cluster_overview';
        const fields = this.overviewColumnDefs
            .map((column: ColGroupDef) => column.children.filter((child: ColDef) => !child.hide).map((child: ColDef) => child.field))
            .flat() as string[];

        this.overviewGridApi.exportDataAsCsv({
            columnKeys: fields,
            columnSeparator: ';',
            fileName: `${fileName}_${format(new Date(), 'yyyyMMdd')}`,
            processCellCallback: (params: ProcessCellForExportParams) => {
                const isPinnedBottomRow = params.node?.rowPinned === 'bottom';

                if (params.column.getColId() === 'approved') {
                    if (params.value === null || isPinnedBottomRow) {
                        return '';
                    } else {
                        return params.value === true ? 'Approved' : 'Not Approved';
                    }
                } else if (params.column.getColId() === 'board_member') {
                    return params.value ?? '';
                } else {
                    return this.utilsService.formatGridNumbersForExport(params);
                }
            },
        });
    }
}
